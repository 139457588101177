export const products = [
    {
        id: 'https://www.gnc.com/weight-management/485500.html',
        image: 'https://www.gnc.com/dw/image/v2/BBLB_PRD/on/demandware.static/-/Sites-master-catalog-gnc/default/dw7f691255/hi-res/485500_BodyDynamix Slimvance XP_Carton.jpg?sw=200&sh=200&sm=fit&qlt=80&format=webp',
        name: 'BodyDynamix Slimvance XP Metabolism Igniter* - Thermogenic | GNC',
        price: '59.99',
    },
    {
        id: 'https://www.gnc.com/fish-oil-omegas/735335.html',
        image: 'https://www.gnc.com/dw/image/v2/BBLB_PRD/on/demandware.static/-/Sites-master-catalog-gnc/default/dwb002e556/hi-res/735335_1.jpg?sw=200&sh=200&sm=fit&qlt=80&format=webp',
        name: 'Triple Strength Fish Oil (60 ct) – For Brain, Eye, Heart Health | GNC',
        price: '29.99',
    },

    {
        id: 'https://www.gnc.com/vitapak-programs/220811.html',
        image: 'https://www.gnc.com/dw/image/v2/BBLB_PRD/on/demandware.static/-/Sites-master-catalog-gnc/default/dwe770e159/hi-res/2023_02_14_updated/220811_GNC Total Lean Burn and Define_Vitapack_Front.jpg?sw=200&sh=200&sm=fit&qlt=80&format=webp',
        name: 'GNC Total Lean® Burn & Define VitaPak® | GNC',
        price: '59.99',
    },
    {
        id: 'https://www.gnc.com/pre-workout-supplements/369881.html',
        image: 'https://www.gnc.com/dw/image/v2/BBLB_PRD/on/demandware.static/-/Sites-master-catalog-gnc/default/dw4e891e76/hi-res/Price_Drop_Images_Banner/369881_Beyond_Raw_Lit_Strawberry_Lemonade_no1badge_PriceDrop.jpg?sw=200&sh=200&sm=fit&qlt=80&format=webp',
        name: 'Beyond Raw LIT Pre-Workout - Strawberry Lemonade | GNC',
        price: '39.99',
    },
    {
        id: 'https://www.gnc.com/meal-replacement-powders/370113.html',
        image: 'https://www.gnc.com/dw/image/v2/BBLB_PRD/on/demandware.static/-/Sites-master-catalog-gnc/default/dwe22f84b7/hi-res/370113_GNC Total Lean Lean Shake 25 Rich Chocolate_Tub.jpg?sw=200&sh=200&sm=fit&qlt=80&format=webp',
        name: 'GNC Total Lean Lean Shake 25 - 12 Servings | GNC',
        price: '39.99',
    },
    //6th product category paage not found
    {
        id: 'https://www.gnc.com/whey-protein/369948.html',
        image: 'https://www.gnc.com/dw/image/v2/BBLB_PRD/on/demandware.static/-/Sites-master-catalog-gnc/default/dw05a7b810/hi-res/GNC-60440/2000px wide JPG/369948_Pro_Performance_100_Whey_Vanilla_Cream_Tub_Front.jpg?sw=200&sh=200&sm=fit&qlt=80&format=webp',
        name: 'GNC Pro Performance 100% Whey - Vanilla Cream | GNC',
        price: '39.99',
    },
    //this is from 7th category
    {
        id: 'https://www.gnc.com/whey-protein/369949.html',
        image: 'https://www.gnc.com/dw/image/v2/BBLB_PRD/on/demandware.static/-/Sites-master-catalog-gnc/default/dwcabd8f71/hi-res/GNC-60440/2000px wide JPG/369949_Pro_Performance_100_Whey_Creamy_Strawberry_Tub_Front.jpg?sw=200&sh=200&sm=fit&qlt=80&format=webp',
        name: 'GNC Pro Performance 100% Whey - Creamy Strawberry  | GNC',
        price: '39.99',
    },
    //this is from 5 category - [370115]
    {
        id: 'https://www.gnc.com/meal-replacement-powders/370115.html',
        image: 'https://www.gnc.com/dw/image/v2/BBLB_PRD/on/demandware.static/-/Sites-master-catalog-gnc/default/dwa9e77293/hi-res/2022_11_08_updated/370115_GNC-Total-Lean-Lean-Shake-25-Mixed-Berry_Tub_Front.jpg?sw=200&sh=200&sm=fit&qlt=80&format=webp',
        name: 'GNC Total Lean Lean Shake 25 - Mixed Berry | GNC',
        price: '39.99',
    },
    //this is from 4th category - [369926]
    {
        id: 'https://www.gnc.com/pre-workout-supplements/369926.html',
        image: 'https://www.gnc.com/dw/image/v2/BBLB_PRD/on/demandware.static/-/Sites-master-catalog-gnc/default/dwbd419711/hi-res/Badged Products - No1Brand/369926_Beyond_Raw_Lit_Gummy_Worm_Tub_PriceDrop_no1Brand.jpg?sw=200&sh=200&sm=fit&qlt=80&format=webp',
        name: 'Beyond Raw Lit Pre-Workout - Gummy Worm | GNC',
        price: '39.99',
    },
    //this is from 3rd category - [220311]
    {
        id: 'https://www.gnc.com/vitapak-programs/220311.html',
        image: 'https://www.gnc.com/dw/image/v2/BBLB_PRD/on/demandware.static/-/Sites-master-catalog-gnc/default/dwd875d7b4/hi-res/214911_web_GNC AMP Mens Ripped Vitapak_Front_Box.jpg?sw=200&sh=200&sm=fit&qlt=80&format=webp',
        name: "GNC Men's Ripped Vitapak® Program With Metabolism + Muscle Support | GNC",
        price: '79.99',
    },
];
