import showdown from 'showdown';

const markdownToHTML = markdown => {
    // console.debug("Markdown: ",markdown)
    const converter = new showdown.Converter(),
    html = converter.makeHtml(markdown);
    // console.debug("HTML: " ,html)
    return html;
}

export default markdownToHTML;