import htmlToMarkdown from "./htmlToMarkdown";

/**
 * Convert all product inf to anchor tags before sending back to API
 * @param {*} message 
 * @returns 
 */
const convertProductInfoToAnchor = (message,selectedProducts) => {
    // console.log('message with product info', message, selectedProducts)
    const element = document.createElement('div');
    element.innerHTML = message;
    [...element.querySelectorAll('section.product')].map((section, index) => {
        const anchor = document.createElement('a');
        // @todo : is this condition failing after replacing product?
        const product = selectedProducts.filter((product)=>(product.id === section.getAttribute('data-id')));
        
        // console.log("from converter",product,selectedProducts,section.getAttribute('data-id'))
        // if()
        anchor.innerHTML = product && product.length ? product[0].name : "Click to see product details";
        anchor.setAttribute('href', section.getAttribute('data-id'));

        section.parentNode.replaceChild(anchor, section);
        // numbering: @todo: this is hacky
        // Hack to get correct numbering
        const number = document.createTextNode(`${index+1}. `);
        anchor.parentNode.insertBefore(number, anchor);

        return section;
    })
    // console.log("message before replacing: ",element.innerHTML.replace(/-\s*\*\*(\d+)\./, '**$1.'))
    // console.debug("message before replacing: ",element.innerHTML.replaceAll('<strong></strong>',''))
    // console.debug('message to send', htmlToMarkdown(element.innerHTML.replaceAll('<strong></strong>','')).replace(/\n/g, "\\n").replaceAll('\n\n- \n\n','\n\n'));
    // replacing more that 4 spaces to avoid turning into code blocks
    // htmlToMarkdown(element.innerHTML.replaceAll('<strong></strong>','')).replace(/  +/g, ' ').replaceAll('\n\n- \n\n','\n\n')
    return htmlToMarkdown(element.innerHTML.replaceAll('<p><strong></strong></p>','')).replaceAll('<p></p>','').replace(/(\d+)\.\s\1/g, '$1.').replace(/  +/g, ' ').replaceAll('\n\n- \n\n','\n\n').replace(/(\d+)\.\s*\\/g, '$1.').replace(/(\d+)\.{2,}/g, '$1.').replace(/-\s*(\d+\\.)/g, '$1');
}

export default convertProductInfoToAnchor;