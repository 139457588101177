import axios from 'axios';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
const API_URL = process.env.REACT_APP_API_SERVER;
const EMAIL = process.env.REACT_APP_FIREBASE_USER_EMAIL_ID;

const authenticateFirebaseDatabase = async () => {
  const auth = getAuth();

  try {
    const response = await axios.post(`${API_URL}/auth/firebaseRTDBFlyingCopilot`, {
      email: EMAIL,
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      /* const authToken =  */ await signInWithCustomToken(auth, response.data.token);
      // console.info({ 'User Authenticated': response.data, "authToken": authToken });
    } else {
      console.error('Error signing in:', response.data.error);
    }
  } catch (error) {
    console.error('Error during sign in:', error);
  }
};

export default authenticateFirebaseDatabase;
