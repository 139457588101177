import { getDatabase, ref, update, onValue } from "firebase/database";

/**
 * Statuses based on copilot editing and submitting
 */
export const USER_STATUS = {
    ACTIVE: "active",
    PAUSED: "paused",
    OFFLINE: 'offline'
}

const urlParams = new URLSearchParams(window.location.search);
const orgId = urlParams.get('orgId');
const projectId = urlParams.get('projectId');
const userId = urlParams.get('copilotId');

/**
 * Update user status
 * @param {*} userId 
 * @param {*} status 
 * @returns 
 */
function updateUserStatus(userId, status) {
  const db = getDatabase();
  return update(ref(db, `${orgId}/${projectId}/copilot/${userId}`), {
    status: status
  });
}

/**
 * Set user as paused, and will be coming back soon
 * @param {*} copilotID 
 */
export function pause(copilotID) {
    updateUserStatus(copilotID, USER_STATUS.PAUSED);
}

/**
 * Mark user as offline
 * @param {*} copilotID 
 */
export function offline(copilotID) {
    updateUserStatus(copilotID, USER_STATUS.OFFLINE);
}

/**
 * Mark user as online
 * @param {*} copilotID 
 */
export function online(copilotID) {
    updateUserStatus(copilotID, USER_STATUS.ACTIVE);
}

/**
 * Same as online
 * @param {*} copilotID 
 */
export function active(copilotID) {
    online(copilotID)
}

