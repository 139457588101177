/**
 * Replaces a product with a new ID.
 * @param {*} message 
 * @param {*} id 
 * @returns 
 */
const replaceProductById = (message, currentProductId, newProductId) => {
    // console.log(message, currentProductId, newProductId, 'replace')
    const element = document.createElement('div');
    element.innerHTML = message;

   [...element.querySelectorAll('section.product')].forEach((product) => {
        if(product.getAttribute('data-id') === currentProductId) {
            product.setAttribute('data-id', newProductId);
        }
    });

    // console.log(element, 'replaced', currentProductId, newProductId)
    return element.innerHTML;
}

export default replaceProductById;
