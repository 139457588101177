import axios from 'axios';

const getProducts = async (query) => {
    const result = await axios.post('https://json-parser-vlrd2qrw7a-ue.a.run.app/parse', {
        "character": query,
        "limit": 10
    });
    return result.data;
}

export default getProducts;