import React, { useState, useEffect, memo } from 'react';
import Markdown from 'react-markdown'
import './ChatHistory.css';
import markdownToHTML from '../../helpers/markdowntoHtml';
import htmlToMarkdown from '../../helpers/htmlToMarkdown';
import convertAnchorToProductInfo from "../../helpers/convertAnchorToProductInfo";
import getAllProductsAsJsonFromLinks from '../../helpers/getAllProductsAsJsonFromLinks';
import convertProductInfoToAnchor from '../../helpers/convertProductInfoToAnchor';
import ProductPreview from '../ProductPreview/productpreview';
import ChatHistoryImages from '../ChatHistoryImages/ChatHistoryImages';
import getProductInfoFromLink from '../../helpers/getProductsFromLinks';
import rehypeRaw from "rehype-raw";

const formatData = (inputData) => {
    // Remove the <!-- --> pattern
    const withoutComments = inputData.replace(/<!--[\s\S]*?-->/g, '');

    // Replace numbers with a single dot (.) with numbers having \. instead
    const correctedNumbers = withoutComments.replace(/(\d+)\.(\s)/g, "$1\\. ");

    // Replace **<a href="link">Name</a>** with [Name](link)
    const formattedText = correctedNumbers.replace(/\*\*<a href="([^"]+)">([^<]+)<\/a>\*\*/g, "[$2]($1)");

    return formattedText;
}

const ChatHistory = memo(({ chatHistory }) => {
    return (

        <div>
            {chatHistory ? chatHistory.map((chat, index) => (
                <div key={index} className="message-wrapper">
                    <div
                        className={
                            'message ' +
                            (chat.role === 'assistant' ? 'assistant' : 'user')
                        }
                    >
                        {/* {chat.role === 'assistant' ? <div className='text-[#157b8a]'>Ryan
                        </div> : <div className=' font-bold'>Customer</div>} */}
                        <Markdown rehypePlugins={[rehypeRaw]}
                        components={{
                            a: props => {
                                return props.href ? (
                                    <><ChatHistoryImages link={props.href} /></>
                                ) : (
                                    <></>
                                );
                            }
                        }}>{formatData(chat.content)}</Markdown>
                    </div>
                </div>
            )) :
                <div className="message-wrapper flex justify-center p-3">
                    <div className={'assistant'}>
                        No message history to show
                    </div>
                </div>
            }
        </div>
    );
});

export default ChatHistory;
