// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
svg {
    max-width: 75px;
    max-height: 75px;
    width: auto;
    height: auto;
  }
  
  #base-path {
    fill: rgb(255, 255, 255);
    stroke: #80808017;
    stroke-width: 50px;
  }

  #motion-path {
    fill: none;
    stroke-width: 50px;
    stroke-dasharray: 2900;
  }

  .childIcon1{
    position: absolute;
    left:0px; 

  }
  .childIcon2{
     position: absolute;
    left:0.1px; 
    font-size: 100px!important;

  }
  .edit-or-letgo{
    position: absolute;
    width: 75px;
    height: 75px;
    left: 50%;
    transform: translateX(-50%);
  }
  .timer-icons ion-icon{
    font-size: 64px;
  }
.edit-or-letgo{
  cursor: pointer;
}

.timer-icons {
  position: absolute;
  top: 5px;
  left: -5px;
}`, "",{"version":3,"sources":["webpack://./src/components/TimeBombButton/TimeBombButton/TimeBombButton.css"],"names":[],"mappings":";AACA;IACI,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,YAAY;EACd;;EAEA;IACE,wBAAwB;IACxB,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,UAAU;IACV,kBAAkB;IAClB,sBAAsB;EACxB;;EAEA;IACE,kBAAkB;IAClB,QAAQ;;EAEV;EACA;KACG,kBAAkB;IACnB,UAAU;IACV,0BAA0B;;EAE5B;EACA;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,SAAS;IACT,2BAA2B;EAC7B;EACA;IACE,eAAe;EACjB;AACF;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;AACZ","sourcesContent":["\nsvg {\n    max-width: 75px;\n    max-height: 75px;\n    width: auto;\n    height: auto;\n  }\n  \n  #base-path {\n    fill: rgb(255, 255, 255);\n    stroke: #80808017;\n    stroke-width: 50px;\n  }\n\n  #motion-path {\n    fill: none;\n    stroke-width: 50px;\n    stroke-dasharray: 2900;\n  }\n\n  .childIcon1{\n    position: absolute;\n    left:0px; \n\n  }\n  .childIcon2{\n     position: absolute;\n    left:0.1px; \n    font-size: 100px!important;\n\n  }\n  .edit-or-letgo{\n    position: absolute;\n    width: 75px;\n    height: 75px;\n    left: 50%;\n    transform: translateX(-50%);\n  }\n  .timer-icons ion-icon{\n    font-size: 64px;\n  }\n.edit-or-letgo{\n  cursor: pointer;\n}\n\n.timer-icons {\n  position: absolute;\n  top: 5px;\n  left: -5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
