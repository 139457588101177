import React from 'react';
import successAnimation from '../../animations/SuccessAnimation.json';
import Lottie from 'lottie-react';

const SuccessCard = () => {
    

    return (
        <div className="w-[100%] h-[100%] bg-black bg-opacity-10 flex justify-center items-center z-100">
            
            <div className="w-[18.3125rem] h-[10.375rem] flex flex-col gap-1 justify-center text-[#76AC5C] bg-white rounded-[0.625rem] shadow-[0_2px_10px_4px_rgba(0,0,0,0.07)] border-b-[#76AC5C] border-4">
                <div className='h-20 w-[100%] flex justify-center'>
                <Lottie animationData={successAnimation} />
            </div>
            <div className="w-[100%] text-center font-[500] text-[1.5rem] leading-[1.66113rems]">
                    Success!
                </div>
                <div className="w-[100%] text-center font-[300] text-[1rem]">
                    Thank you for your feedback
                </div>
            </div>
        </div>
    );
};

export default SuccessCard;
