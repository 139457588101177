// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nomessage-container {
    height: 100%;
    width: 100%;
    background-color: #242424;
    /* background-image: url('../../assets/copilot-background.png'); */
    /* background-size: cover; */
  }`, "",{"version":3,"sources":["webpack://./src/components/NoMessageCard/NoMessageCard.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,yBAAyB;IACzB,kEAAkE;IAClE,4BAA4B;EAC9B","sourcesContent":[".nomessage-container {\n    height: 100%;\n    width: 100%;\n    background-color: #242424;\n    /* background-image: url('../../assets/copilot-background.png'); */\n    /* background-size: cover; */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
