// react/productlist.js

import React from 'react';
import ProductPreview from '../ProductPreview/productpreview';
import { IonItem, IonList } from '@ionic/react';
import NoProductsIcon from '../../assets/no-products-icon.svg'

const ProductList = ({ ...props }) => {
        return (<IonList inset={true}>
        {props.products && props.products.length ? props.products.map( product => {
            return <IonItem key={'product' + product.id}>
                        <ProductPreview
                        mode="toplist"
                        id={product.id}
                        onClick={props.onClick}
                        {...product}
                        action={props.action}
                        />
                    </IonItem>;
                    
        }) : <div className='w-full bg-[#f4f5f8] h-[20rem] flex justify-center'>
            <img src={NoProductsIcon} className='p-10 mr-2'>
                </img></div>}
        </IonList>)
}

export default ProductList;
