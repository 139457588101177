import { useLayoutEffect, useRef, useState, useEffect } from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import { pencilOutline, checkmarkOutline } from 'ionicons/icons';
import { gsap } from 'gsap';
import './TimeBombButton.css';

const TimeBombButton = ({
    isEditing,
    setIsEditing,
    animate,
    timer,
    loading,
    timeLimit,
    updateQuestion
}) => {
    const el = useRef();
    const tl = useRef();
    const [strokeDashoffset, setStrokeDashoffset] = useState(2900);
    const [strokeColor, setStrokeColor] = useState('#42a6e0');

    useEffect(() => {
        if(timer < timeLimit*1/3)
        {
            setStrokeColor('#42a6e0')
        }
        else if(timer < timeLimit*2/3)
        {
            setStrokeColor('#ffb800')
        }
        else if(timer < timeLimit)
        {
            setStrokeColor('#af0909')
        }
    }, [timer]); // Run this effect whenever the timer prop changes

    // const [icon, setIcon] = useState(true);
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            if(loading && tl.current) {
                tl.current.pause();
            }
            else if(!loading && tl.current) {
                tl.current.play();
            }
            else if(animate) {
                const obj = { strokeDashoffset: 2900 }
                tl.current = gsap
                    .timeline()
                    .to(obj, {
                        strokeDashoffset: 0, //2900
                        duration: timeLimit,
                        onUpdate: () => {
                            setStrokeDashoffset(obj.strokeDashoffset);
                            // setStrokeColor(obj.strokeDashoffset < 1000 ? '#af0909' : obj.strokeDashoffset < 2000 ? '#ffb800' : '#42a6e0');
                            // setStrokeColor(obj.strokeDashoffset < 300 ? '#af0909' : obj.strokeDashoffset < 1243 ? '#ffb800' : '#42a6e0');
                            // setStrokeColor(obj.strokeDashoffset < 300 ? '#af0909' : obj.strokeDashoffset < 1243 ? '#ffb800' : '#42a6e0');
                        }
                    })
            }
        }, el);
        // console.log(ctx);
    }, [animate, loading, isEditing, timeLimit]);
    return (
        <div ref={el}>
            <div className="edit-or-letgo" >
                <svg
                    style={{ position: 'absolute' }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1000 1000"
                    width="1000"
                    height="1000"
                >
                    <circle id="base-path" r="450" cx="500" cy="500" />
                    <circle id="motion-path" stroke={animate ? strokeColor : '#3880ff'} strokeDashoffset={animate? strokeDashoffset : 0} r="450" cx="500" cy="500" />
                </svg>
                <IonButton
                    shape="round"
                    fill="clear"
                    onClick={() => {
                        if(isEditing){
                            tl.current.progress(1);
                            setStrokeColor('#42a6e0');
                            setIsEditing(true);
                        } else {
                            updateQuestion()
                        }

                    }}
                    className="timer-icons"
                >
                    {<IonIcon
                    className="childIcon2" icon={checkmarkOutline} size="large"></IonIcon>}

                    {/* {isEditing ? (
                        <div>editing</div>
                    ) : (
                        <div>normal</div>
                    )} */}
                </IonButton>
            </div>
        </div>
    );
};

export default TimeBombButton;
