import axios from 'axios';
import markdownToHTML from './markdowntoHtml';
import htmlToMarkdown from './htmlToMarkdown';

/**
 * Get the product info and returns the HTML for the product card
 * @param {*} link 
 * @returns 
 */
export const getProductInfoFromLinkAsHTML = async (link) => {
    let headersList = {
        "Accept": "*/*",
        "Content-Type": "application/json"
    }

    let bodyContent = JSON.stringify({
        "url": link
    });

    let reqOptions = {
        url: `${process.env.REACT_APP_API_SERVER}/api/chat/ogData`,
        method: "POST",
        headers: headersList,
        data: bodyContent,
    }

    let response = await axios.request(reqOptions);
    let data = response.data;
    return `
                <p className="image">
                    {${data} && ${data['og:image']} && (<img
                        src="${data['og:image'] || ''}"
                        alt="Product Image"
                    />)}
                </p>
                <p className="title">${data['og:title'] || 'Title missing'}</p>
                <p className="price">${data['product:price:amount'] || 'Price missing'} ${data['product:price:currency'] || ''}</p>
                <p>
                    <a
                        className="action"
                        target="_blank"
                        href="${data['og:url'] ? data['og:url'] : link}"
                    >
                        See Details
                    </a>
                </p>`   
};


/**
 * Expand the anchor tags to Complete product info and return the full HTML
 * @param {*} message 
 * @returns 
 */
// const convertAnchorToProductInfoHTMLRaw = async(message) => {
//     const element = document.createElement('div');
//     element.innerHTML = message;
//     await Promise.all([...element.querySelectorAll('a')].map(async (anchor) => {
//         console.log('href', anchor.href);
//         const productInfoHTML = await getProductInfoFromLinkAsHTML(anchor.href);
//         // const productInfoHTML = await getProductInfoFromLinkAsCKESection(anchor.href);
//         const div = document.createElement('div');
//         div.innerHTML = productInfoHTML;
//         anchor.parentNode.replaceChild(div, anchor);
//         console.log('href', productInfoHTML);
//     }))
//     console.log(element.innerHTML, 'convertAnchorToProductInfo');
//     return element.innerHTML;
// }

const processLists = (message) => {
    const element = document.createElement('div');
    element.innerHTML = message;
    [...element.querySelectorAll('li')].forEach(li => {
        const anchor = li.querySelector('a');
        li.innerHTML = `${li.innerHTML}<section class="product" data-id="${anchor.href}"></section>`
    });
    return element.innerHTML;
}

/**
 * Expand the anchor tags to Complete product info and return the full HTML
 * @param {*} message 
 * @returns 
 */
const convertAnchorToProductInfo = async(message) => {
    // message = processLists(message);
    const element = document.createElement('div');
    element.innerHTML = message;
    // element.innerHTML = markdownToHTML(message);
    let check = element.innerHTML.includes('Contact Us')
    let check2 = element.innerHTML.includes('contact-us')
    if(!check && !check2)
    {
        await Promise.all([...element.querySelectorAll('a')].map(async (anchor) => {
            // console.log('Anchor tag info: ',anchor)
            const section = document.createElement('section');
            section.setAttribute('class', 'product');
            section.setAttribute('data-id', anchor.href);

            // const p = document.createElement('p');
            // p.innerHTML = "product expansion"
            // p.appendChild(section);
            // anchor.parentNode.innerHTML += '<span class="product" data-id="'+anchor.href+'">&nbsp;</span>'
            // anchor.parentNode.insertBefore(section, anchor.nextSibling);
            anchor.parentNode.replaceChild(section, anchor);
        }))
    }
    // console.log('AnchorToProductInfo', element.innerHTML);
    return element.innerHTML;
    // return htmlToMarkdown(element.innerHTML);
}


export default convertAnchorToProductInfo;
