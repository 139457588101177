// import React from 'react';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getDatabase, ref, onValue, DatabaseReference, off } from 'firebase/database';
import axios from 'axios';

const initFirebaseMessaging = () => {
    const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,

    };

    const firebaseApp = initializeApp(firebaseConfig);
    // const messaging = getMessaging(firebaseApp);

    let db = getDatabase(firebaseApp);
    // console.log('messaging: ', messaging);

    // // const envIsDev = process.env.NODE_ENV === 'development';

    // const vapidKey =
    //     'BAtd3MBSUcLlhK2azKWdNqicZrTWcJDnc59RB4MxYkuyKAMedqfSodBhYHdATaxGqkwy70wCW1GXtEMZULPgDYU';
    // // const vapidKey = 'Z0edAOZFcuD43qVskZUfgC8rZXvI-K_ZM-mGuFFvrBo';

    // getToken(messaging, { vapidKey })
    //     .then((currentToken) => {
    //         if (currentToken) {
    //             // Send the token to your server and update the UI if necessary
    //             // ...
    //             console.log(currentToken);
    //             // TODO: Send token to server for sending notifications using topic subscription

    //             let payload = {
    //                 "token":  currentToken
    //                 };
    //             axios.post(`${process.env.REACT_APP_API_SERVER}/api/chatbot/subscribeToTopic`, payload);
    //         } else {
    //             // Show permission request UI
    //             console.log(
    //                 'No registration token available. Request permission to generate one.'
    //             );
    //             // ...
    //         }
    //     })
    //     .catch((err) => {
    //         console.log('An error occurred while retrieving token. ', err);
    //         // ...
    //     });

    // onMessage(messaging, (payload) => {
    //     console.log('Message received. ', payload);
    //     const notificationTitle = payload.notification.title;
    //     const notificationOptions = {
    //         body: payload.notification.body,
    //         icon: payload.notification.icon || payload.notification.image,
    //     };
    //     showNotification(notificationTitle, notificationOptions);
    //     // self.registration.showNotification(notificationTitle, notificationOptions);
    //     // ...
    //     // navigator.serviceWorker.ready.then((registration) => {
    //     //     console.log('Service Wroker REady', registration);
    //     //     registration.showNotification(notificationTitle, notificationOptions);
    //     //   });
    // });

    return {
        app: firebaseApp,
        database: db
    }
}

// navigator.serviceWorker.register("firebase-messaging-sw.js").then(() => {
//     console.log('registered');
// });

// function showNotification(title, body) {
//   Notification.requestPermission().then((result) => {
//     if (result === "granted") {
//       navigator.serviceWorker.ready.then((registration) => {
//         registration.showNotification(title, body);
//       });
//     }
//   });
// }

export default initFirebaseMessaging;
