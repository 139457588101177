/**
 * Removes a product basedon the ID and responds with updated HTML message
 * @param {*} message 
 * @param {*} id 
 * @returns 
 */
const removeProductById = (message, id) => {
    // console.log(message, id, 'remove')
    const element = document.createElement('div');
    element.innerHTML = message;

   [...element.querySelectorAll('section.product')].forEach((product) => {
        if(product.getAttribute('data-id') === id) {
            product.parentNode.removeChild(product);
            return; // @todo returning here to avoid deleting duplicates, revisit duplicate scenario
        }
    });

    // console.log(element, 'removed', id)
    return element.innerHTML;
}

export default removeProductById;
