import React from 'react';
import GNCCopilot from '../../assets/logo512.png'
import techolution from '../../assets/techolution.svg'
import './LoginCard.css'
import { IonButton, IonIcon, IonInput, IonToggle } from '@ionic/react';

const LoginCard = ({ ...props }) => {
    return (
        <div className="nomessage-container flex flex-col justify-center items-center">
            <div className=" relative w-[22.375rem] h-[28rem] bg-white rounded-[0.625rem] shadow-[0_2px_10px_4px_rgba(0,0,0,0.07)] flex justify-center items-center flex-col gap-1">
                <img
                    src={GNCCopilot}
                    alt="No Message Image"
                    className="w-[8rem] h-[8rem]"
                />
                <div className='mb-10'>
                    <form>
                        <div class="login-page__inputs">
                            <IonInput
                                className='mb-5'
                                class="login-page__inputs__field"
                                label="Username"
                                label-placement="floating"
                                formControlName="usernm"
                                fill="outline"
                                placeholder="Username"
                                mode="md"
                                type="email"
                            >
                            </IonInput>
                            {/* <div class="mt-5">
                                <IonInput
                                    label="Password"
                                    label-placement="floating"
                                    formControlName="passwd"
                                    fill="outline"
                                    placeholder="Password"
                                    mode="md"
                                    type="password">
                                </IonInput>
                                <IonIcon class="login-page__inputs__field__password-icon" name="passwordIcon"></IonIcon>
                            </div> */}
                        </div>
                        <div class="login-page__submit_btn">
                            <IonButton 
                                color="dark" 
                                type="submit" 
                                expand="block" 
                                disabled="false">Login</IonButton>
                        </div>
                    </form>
                </div>
                <div>

                </div>
                <div className='absolute bottom-5 text-center w-[5rem]'>
                    <span className='text-[0.6rem]'>Powered by</span>
                    <img
                        className='inline'
                        src={techolution}
                        alt="techolution"
                    />
                </div>
            </div>
        </div>
    );
};

export default LoginCard;
