// react/productlist.js

import React from 'react';
import ProductPreview from '../ProductPreview/productpreview';
import { IonItem, IonList } from '@ionic/react';

export default class SelectedProductList extends React.Component {
    render() {
        return <IonList inset={true}>
                {this.props.products.length === 0? 
                    <IonItem color="light">No products selected</IonItem> :
                ''}
                {this.props.products.map( product => {
                    return <IonItem 
                                onClick={() => {
                                    this.props.setSelectedProductForEditing(product.id)
                                }}
                                key={'sel' + product.id}
                                color={this.props.selectedProductForEditing === product.id ? 'primary': ''}>
                                <ProductPreview
                                selectedProductForEditing={this.props.selectedProductForEditing || ''}
                                mode="list"
                                id={product.id}
                                onClick={this.props.onClick}
                                {...product}
                                />
                            </IonItem>;
                })}
                </IonList>
    }
}
