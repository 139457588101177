import React ,{useState,useMemo, memo}from 'react';
import axios from 'axios';
//{name,image,price,currency,id,}

const productCache = {};

function memoize(method) {
  return async (link) => {
      productCache[link] = productCache[link] || await method(link);
      return productCache[link];
  };
}

const isValidUrl = (urlString) => {
  var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
  return !!urlPattern.test(urlString);
}

const ChatHistoryImages = memo(({ link }) => {
    // console.log('chat history images rendered');
    // console.log(link);
    const [linkData, setLinkData] = useState(null);


    useMemo(() => {
        const getData = memoize(async (link) => {
            let headersList = {
                Accept: '*/*',
                'Content-Type': 'application/json',
            };

            let bodyContent = JSON.stringify({
                url: link,
            });

            let reqOptions = {
                url: `${process.env.REACT_APP_API_SERVER}/api/chat/ogData`,
                method: 'POST',
                headers: headersList,
                data: bodyContent,
            };

            let response = await axios.request(reqOptions);
            //console.log(response.data);

            return response.data;
        });
        const setData = async (link) => {
            const data = await getData(link);
            setLinkData(data);
        };

        if (isValidUrl(link)) {
          setData(link);
      }

    }, [link]);

    return (
        <>
            {linkData && link ? (!link.includes('contact-us') ? <Product data={linkData} link={link}/>: <span><a href={link} className='underline text-[#157b8a]'>Contact Us</a></span>) : <Loading link={link}></Loading>}
        </>
    );
});

const Product = (props) => {
  const { data, link} = props;
  return (
    <>
       <span className='link text-[#157b8a] font-[500]'>{data['og:title']}</span>
       <span className="product">
          <span className="image">    
              {data && data['og:image'] && (
                  <img
                    className='mix-blend-multiply'
                    src={data['og:image']}
                    alt="Product Image"
                    style={{ backgroundColor: '#FEF3F2' }}
                      />
              )}
          </span>
          <span className="title">{data['og:title']}</span>
          <span className="price">{data['product:price:amount']} {data['product:price:currency']}</span>
          <span>
              <a
                  className="action text-black"
                  target="_blank"
                  href={data['og:url'] ? data['og:url'] : link}
              >
                  Go to Product Page
              </a>
          </span>
      </span> 
    </>
  );
};

const Loading = (props) => {
  const { link } = props;
  return (
      <a
          className='link'
          target="_blank"
          href={link}
      >
          <span className='link'>Click to see product details </span>
      </a>
  );
};

export default ChatHistoryImages;
