
import { Plugin } from '@ckeditor/ckeditor5-core';
import { ButtonView } from '@ckeditor/ckeditor5-ui';
import plusIcon from '@ckeditor/ckeditor5-core/theme/icons/plus.svg'

export default class AddProductToolbar extends Plugin {
    init() {
        const editor = this.editor;
        const openDialog = editor.config.get( 'products' ).openProductDialog;
        editor.ui.componentFactory.add( 'addproduct', () => {

            const button = new ButtonView();

			button.set( {
				label: 'Add Product',
				withText: true,
                class: 'plugin-button',
                icon: plusIcon
			} );

            // Execute a callback function when the button is clicked.
            button.on( 'execute', () => {
                openDialog();
            } );

            return button;
        } );
    }
}