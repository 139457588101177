import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
const ResponseNeeded = ({ createdAt }) => {
    const [elapsedTime, setElapsedTime] = useState('');
    const [textColor, setTextColor] = useState('black');
    const elapsedMinutesRef = useRef(0);
    useEffect(() => {
        // Parse the given timestamp
        const startTime = moment(createdAt);
        // Update elapsed time every second
        const intervalId = setInterval(() => {
            // Calculate the difference
            const currentTime = moment();
            const duration = moment.duration(currentTime.diff(startTime));
            // Extract elapsed hours, minutes, and seconds
            const elapsedHours = duration.hours();
            const elapsedMinutes = duration.minutes();
            elapsedMinutesRef.current = duration.minutes();
            const elapsedSeconds = duration.seconds();
            // Display the elapsed time
            // setElapsedTime(${elapsedHours} hours, ${elapsedMinutes} minutes, ${elapsedSeconds} seconds);
            let formattedTime = '';
            if (elapsedHours > 0) {
                formattedTime = `${elapsedHours} ${
                    elapsedHours === 1 ? 'hour' : 'hours'
                }`;
                setTextColor('#CC0D30');
            } else if (elapsedMinutes > 0) {
                setTextColor('#CC0D30');
                formattedTime = `${elapsedMinutes} ${
                    elapsedMinutes === 1 ? 'minute' : 'minutes'
                }`;
            } else {
                formattedTime = `${elapsedSeconds} ${
                    elapsedSeconds === 1 ? 'second' : 'seconds'
                }`;
            }
            // Display the formatted elapsed time
            setElapsedTime(formattedTime);
        }, 1000);
        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [createdAt]);
    // const textColorStyle = {
    //     color: elapsedMinutesRef.current > 1 ? 'red' : 'black',
    // };
    return (
        <div className="w-[100%] flex justify-center py-2 rounded-[0.5rem]">
            <div className="w-[90%] text-center" style={{color: textColor}}>
                <span className="font-normal leading-none">
                    Response needed:
                </span>{' '}
                <span className="font-light leading-none">
                    User on hold since {elapsedTime}{' '}
                </span>
            </div>
        </div>
    );
};
export default ResponseNeeded;