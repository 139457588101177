import axios from 'axios';
// import {products} from '../products/products';
/**
 * Get the product info and returns the HTML for the product card
 * @param {*} link 
 * @returns 
 */

/**
 * Memoize for limiting ogtag calls
 * @param {*} method 
 * @returns 
 */
function memoize(method) {
    let cache = {};
    return async (link) =>  {
        cache[link] = cache[link] || await method(link);
        if(!cache[link]) { // null scenario
            delete cache[link]; //dont cache if error
        }
        return cache[link];
    };
}

export const getProductInfoFromLink = memoize(async (link) => {
    // const sleep = ms => new Promise(
    //     resolve => setTimeout(resolve, ms));
    let headersList = {
        "Accept": "*/*",
        "Content-Type": "application/json"
    }

    let bodyContent = JSON.stringify({
        "url": link
    });

    let reqOptions = {
        url: `${process.env.REACT_APP_API_SERVER}/api/chat/ogData`,
        method: "POST",
        headers: headersList,
        data: bodyContent,
    }

    let response = await axios.request(reqOptions);

    if(response.status === 200) {
        let data = response.data;
        // console.log(data, 'RESPONSE')
        return ({
            id: link,
            href: data['og:url'],
            name: data['og:title'],
            price: data['product:price:amount'],
            currency: data['product:price:currency'],
            image: data['og:image']
        })
    } else {
        return null;
    }
});

export default getProductInfoFromLink;
