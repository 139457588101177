import getProductInfoFromLink from "./getProductsFromLinks";

/**
 * Expand the anchor tags to Complete product info and return all products as an array of JSON
 * TODO: how to get location of each or replace inline like AutoLink
 * @param {*} message 
 * @returns 
 */
const getAllProductsAsJsonFromLinks = async(message) => {
    const element = document.createElement('div');
    element.innerHTML = message;
    const json = await [...element.querySelectorAll('section.product')]
            .reduce(async (acc, section) => {
                // console.log(acc, 'accumulator');
                let accumulator =  await acc;
                let product = await getProductInfoFromLink(section.getAttribute('data-id'));
                if(product) {
                    accumulator = [...accumulator, product];
                } else {
                    // handle of tag error
                    accumulator = [...accumulator, {
                        id: section.getAttribute('data-id'),
                        title: null,
                        price: null,
                        currency: null,
                        image: null
                    }]
                }
                return accumulator;
            }
            , Promise.resolve([]))
    // console.log(json, message, 'json');
    return json;
}

// const getAllProductsAsJsonFromLinksBeforeConversion = async(message) => {
//     const element = document.createElement('div');
//     element.innerHTML = message;
//     const json = await [...element.querySelectorAll('a')]
//             .reduce(async (acc, anchor) => {
//                 console.log(acc, 'accumulator');
//                 let accumulator =  await acc;
//                 let product = await getProductInfoFromLink(anchor.href);
//                 accumulator = [...accumulator, product];
//                 return accumulator;
//             }
//             , Promise.resolve([]))
//     console.log(json, 'json');
//     return json;
// }

export default getAllProductsAsJsonFromLinks;