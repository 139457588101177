import React from 'react';

const ScoreCard = (props) => {
    return (
        <div className="inline-flex h-[1.3125rem]  text-center  text-[0.875rem] justify-center items-center gap-[0.5rem] rounded-r-[0.54213rem]">
            <div className={"w-[0.67306rem] mt-[0.1rem] h-[0.625rem] " + ((+props.score) > +(props.threshold/2) ? 'bg-[#f1b614]' : 'bg-[#CD0D31]') + " rounded-full ml-[1rem]"}></div>
            <div className={"text-[400] "+ ((+props.score) > +(props.threshold/2) ? 'text-[#f1b614]' : 'text-[#CD0D31]')}>{props.score || '--'}% Score</div>
        </div>
    );
};

export default ScoreCard;
