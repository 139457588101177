export let copilotID = null;

const getCopilotId = async(copilotIDMock) => {
    if(copilotID) {
        return copilotID;
    }
    const sleep = ms => new Promise(resolve => setTimeout(resolve(copilotIDMock), ms));
    const response = await sleep(5000);
    copilotID = response;
    return copilotID;
}

export default getCopilotId;